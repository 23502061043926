import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import SEO from "../components/SEO"
import { useStaticQuery, graphql } from "gatsby"
import CondensedNote from "../components/Notes/CondensedNote"

const query = graphql`
  query allNotes {
    notes: allFile(
      filter: {
        sourceInstanceName: { eq: "generated" }
        extension: { eq: "mdx" }
        childMdx: {
          frontmatter: { type: { eq: "BOOK_NOTES" } }
          fields: { show: { eq: true } }
        }
      }
      sort: { order: DESC, fields: childMdx___frontmatter___published_on }
    ) {
      edges {
        node {
          childMdx {
            frontmatter {
              title
              last_updated_on
              slug
              cover {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Notes = () => {
  const { notes } = useStaticQuery(query)

  return (
    <Layout>
      <SEO title="Notes" />
      <Wrapper>
        <div className="container">
          <h1>Book Notes</h1>
          <p>
            My notes capture the gist of ideas in books and are generally meant
            for myself, for later reflection.
          </p>
          <p>
            These notes are not meant to be complete - if I was already familiar
            with the ideas in a book, I skip noting them. You may end up
            extracting a different set of ideas from the same book. For that
            reason, I would suggest that you use these notes to understand the
            substance of a book quickly, but read the book in entirety if you
            think it holds promise.
          </p>
          <div className="center">
            {notes.edges.map(({ node }, index) => {
              return <CondensedNote key={index} note={node} />
            })}
          </div>
        </div>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  .center {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }

  @media screen and (max-width: 500px) {
    .container {
      padding: 0 1em;
    }
  }
  @media screen and (min-width: 300px) {
    .center {
      grid-template-columns: repeat(2, minmax(50px, 1fr));
    }
  }
  @media screen and (min-width: 600px) {
    .center {
      grid-template-columns: repeat(3, minmax(50px, 1fr));
    }
  }
  @media screen and (min-width: 900px) {
    .center {
      grid-template-columns: repeat(6, minmax(100px, 1fr));
    }
  }
  @media screen and (min-width: 1200px) {
    .center {
      max-width: 1230px;
    }
  }
`

export default Notes
